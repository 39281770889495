import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ChallengeFile = () => {
  const { challengeId } = useParams();

  useEffect(() => {
    // http://localhost:3000/.well-known/acme-challenge/cR7Zihx6PXiNZX_a3HJMuWR-hfL5JEd_kbJgBMUSONE

    const fileContent = `cR7Zihx6PXiNZX_a3HJMuWR-hfL5JEd_kbJgBMUSONE.nwe_MxDUa80E46crXC46jr2QQNWSLnXWozlEPjZevdk`;

    // Create a Blob with the specified content and type
    const blob = new Blob([fileContent], { type: 'application/octet-stream' }); // Set the type to 'application/octet-stream'

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', "cR7Zihx6PXiNZX_a3HJMuWR-hfL5JEd_kbJgBMUSONE"); // Set the filename without extension

    // Trigger the click event on the link
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  }, [challengeId]);

  return null; // This component doesn't render anything visible in the UI
};

export default ChallengeFile;