import { Helmet } from 'react-helmet-async';
import React, {useState, useEffect} from "react";
// @mui
import { Grid, Container, Typography } from '@mui/material';
// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [stats, setStats] = useState([])
  const [countByDate, setCountByDate] = useState([])
  const [countByDateAvg, setCountByDateAvg] = useState([])
  const [pricePerDistrictYerevan, setPricePerDistrictYerevan] = useState([])
  const [countPerStreet, setCountPerStreet] = useState([])
  const [pricePerProvince, setPricePerProvince] = useState([])
  const [countPerCity, setCountPerCity] = useState([])

  useEffect(() => {
    fetch("/api/items/stats")
    .then((data) => data.json())
    .then((data) => setStats(data))
  }, []);

  useEffect(() => {
    fetch("/api/items/countByDate")
    .then((data) => data.json())
    .then((data) => {
      const countItems = [];
      const avgItems = [];
      data.forEach(element => {
        const label = element['created'];
        countItems.push({ label: label, value: element['count'] });
        avgItems.push({ label: label, value: element['avg'] });
      });
      setCountByDate(countItems);
      setCountByDateAvg(avgItems);
    })
  }, []);

  useEffect(() => {
    fetch("/api/items/price/yerevan/district")
    .then((data) => data.json())
    .then((data) => {
      const result = {};
      result.district = [];
      result.count = [];
      result.avg = [];
      data.forEach(element => {
        result.district.push(element['district']);
        result.count.push(element['count']);
        result.avg.push(element['avg']);
      });
      setPricePerDistrictYerevan(result);
    })
  }, []);

  useEffect(() => {
    fetch("/api/items/perStreet")
    .then((data) => data.json())
    .then((data) => {
      const result = [];
      data.forEach(element => {
        result.push({ label: element['street'], value: element['count'] });
      });
      setCountPerStreet(result);
    })
  }, []);

  useEffect(() => {
    fetch("/api/items/price/province")
    .then((data) => data.json())
    .then((data) => {
      const result = {};
      result.province = [];
      result.count = [];
      result.avg = [];
      data.forEach(element => {
        result.province.push(element['province']);
        result.count.push(element['count']);
        result.avg.push(element['avg']);
      });
      setPricePerProvince(result);
    })
  }, []);

  useEffect(() => {
    fetch("/api/items/perCity")
    .then((data) => data.json())
    .then((data) => {
      const result = [];
      data.forEach(element => {
        result.push({ label: element['city'], value: element['count'] });
      });
      setCountPerCity(result);
    })
  }, []);

  return (
    <>
      <Helmet>
        <title> Ամփոփ </title>
      </Helmet>

      <Container maxWidth="xl">
      <Typography variant="h5" sx={{ mb: 5 }}>
          Անշարժ գույքի ամփոփ վիճակագրությունը
        </Typography>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={7}>
            <AppWebsiteVisits
              title="Հայտարարությունները՝ ըստ Երեւանի Վարչական Շրջանների"
              subheader="Վերջին 7 օրվա"
              chartLabels={pricePerDistrictYerevan.district}
              chartData={[
                {
                  name: 'Բնակարանների Քանակը',
                  type: 'area',
                  fill: 'solid',
                  data: pricePerDistrictYerevan.count,
                },
                {
                  name: 'Միջին Գինը ($)',
                  type: 'column',
                  fill: 'gradient',
                  data: pricePerDistrictYerevan.avg,
                }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppCurrentVisits
              title="Ամենաշատ Հայտարարություններով 7 Փողոցները"
              chartData={countPerStreet}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={7}>
            <AppConversionRates title="Ուսումնասիրված հայտարարությունները" subheader="(օրական)" chartData={countByDate} />
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppConversionRates title="Հայտարարությունների միջին գինը" subheader="(օրական)" chartData={countByDateAvg} reversed={true} />
          </Grid>   

          <Grid item xs={12} md={6} lg={7}>
            <AppWebsiteVisits
              title="Հայտարարությունները՝ ըստ Մարզերի"
              subheader="Վերջին 7 օրվա"
              chartLabels={pricePerProvince.province}
              chartData={[
                {
                  name: 'Բնակարանների Քանակը',
                  type: 'area',
                  fill: 'solid',
                  data: pricePerProvince.count,
                },
                {
                  name: 'Միջին Գինը ($)',
                  type: 'column',
                  fill: 'gradient',
                  data: pricePerProvince.avg,

                }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppCurrentVisits
              title="Ամենաշատ Հայտարարություններով 7 Մարզային Քաղաքները"
              chartData={countPerCity}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Վաճառվող Բնակարաններ" total={stats.countAll} color="primary" icon={'mdi:house-analytics'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Մշակված Հայտարարություններ" total={stats.countProcessed} color="info" icon={'mdi:house-edit'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Վաճառվող Երեւանում" total={stats.countCapital} color="primary" icon={'mdi:house-city-outline'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Վաճառվող Մարզերում" total={stats.countProvince} color="info" icon={'mdi:house-city'} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
