import moment from "moment";
import { Helmet } from 'react-helmet-async';
import React, {useState, useEffect} from "react";
// @mui
import { Grid, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// sections
import {
  AppWebsiteVisits,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function YerevanAppPage() {
  const theme = useTheme();
  const priceWeeklyInit = {label:[]};
  const priceWeeklyByDistrictInit = {label:[], chartData:[]};

  const [priceWeekly, setPriceWeekly] = useState(priceWeeklyInit)
  const [priceWeeklyByDistrict, setPriceWeeklyByDistrict] = useState(priceWeeklyByDistrictInit)

  useEffect(() => {
    fetch("/api/capital/price?interval=MONTH&limit=10")
    .then((data) => data.json())
    .then((data) => {
        const result = {};
        result.label = [];
        result.count = [];
        result.avg = [];
        result.median = [];
        data.reverse().forEach(element => {
          result.label.push(moment(element['time_range']).format('DD/MM/YYYY'));
          result.count.push(element['count']);
          result.avg.push(element['avg']);
          result.median.push(element['median']);
        });
        setPriceWeekly(result);
    })
  }, []);

  useEffect(() => {
    fetch("/api/capital/district/priceWeekly")
    .then((data) => data.json())
    .then((data) => {
        const options = {};
        options.label = data['weeks'].map(x => moment().week(x).format('DD/MM/YYYY'));
        options.chartData = [];
        let districts = data['districts'];

        let sortedDistricts = Object.keys(districts).sort().reduce((accumulator, key) => {
            accumulator[key] = districts[key];
            return accumulator;
          }, {});
        
          Object.keys(sortedDistricts).forEach((key) => {
            options.chartData.push ( {
                  name: key,
                  type: 'line',
                  fill: 'solid',
                  data: Object.values(sortedDistricts[key]),
                  isHidden: true
              });
          });
    
        setPriceWeeklyByDistrict(options);
    })
  }, []);

  return (
    <>
      <Helmet>
        <title> Երեւան </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mb: 5 }}>
            Երեւան քաղաքի գների վիճակագրությունը
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={0}>
              <AppWebsiteVisits
                title="Բնակարանի միջին գները Երեւանում"
                subheader="Վերջին 10 ամիսները"
                chartColors={[
                  theme.palette.success.dark,
                  theme.palette.error.dark,
                ]}
                chartLabels={priceWeekly.label}
                chartData={[
                  {
                    name: 'Բնակարանների Քանակը',
                    type: 'bar',
                    fill: 'solid',
                    data: priceWeekly.count,
                  },
                  {
                    name: 'Միջին Գինը ($)',
                    type: 'line',
                    fill: 'solid',
                    data: priceWeekly.avg,
                  },
                  {
                    name: 'Գնի կիսորդը(մեդիան) ($)',
                    type: 'line',
                    fill: 'solid',
                    data: priceWeekly.median,
                  }
                ]}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={0}>
              <AppWebsiteVisits
                  title="Վարչական շրջանների բնակարանների միջին գինը"
                  subheader="Վերջին 10 շաբաթները"
                  chartColors={[
                    theme.palette.success.dark,
                    theme.palette.error.dark,
                  ]}
                  chartLabels={priceWeeklyByDistrict.label}
                  chartData={priceWeeklyByDistrict.chartData}
                />
            </Grid>
          </Grid>
      </Container>
    </>
  );
}
