import moment from "moment";
import { Helmet } from 'react-helmet-async';
import React, {useState, useEffect} from "react";
// @mui
import { Grid, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function ProvinceAppPage() {
  const theme = useTheme();
  const priceWeeklyInit = {label:[]};
  const priceWeeklyByProvinceInit = {label:[], chartData:[]};

  const [priceWeekly, setPriceWeekly] = useState(priceWeeklyInit)
  const [priceWeeklyByProvince, setPriceWeeklyByProvince] = useState(priceWeeklyByProvinceInit)

  useEffect(() => {
    fetch("/api/province/price?interval=MONTH&limit=10")
    .then((data) => data.json())
    .then((data) => {
        const result = {};
        result.label = [];
        result.count = [];
        result.avg = [];
        result.median = [];
        data.reverse().forEach(element => {
          result.label.push(moment(element['time_range']).format('DD/MM/YYYY'));
          result.count.push(element['count']);
          result.avg.push(element['avg']);
          result.median.push(element['median']);
        });
        setPriceWeekly(result); 
    })
  }, []);

  useEffect(() => {
    fetch("/api/province/province/priceWeekly")
    .then((data) => data.json())
    .then((data) => {
        const options = {};
        options.label = data['weeks'].map(x => moment().week(x).format('DD/MM/YYYY'));
        options.chartData = [];
        let provinces = data['provinces'];

        let sortedProvinces = Object.keys(provinces).sort().reduce((accumulator, key) => {
            accumulator[key] = provinces[key];
            return accumulator;
          }, {});
        
          Object.keys(sortedProvinces).forEach((key) => {
            options.chartData.push ( {
                  name: key,
                  type: 'line',
                  fill: 'solid',
                  data: Object.values(sortedProvinces[key]),
                  isHidden: true
              });
          });
    
          setPriceWeeklyByProvince(options);
    })
  }, []);

  return (
    <>
      <Helmet>
        <title> Մարզեր </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mb: 5 }}>
            Մարզերի վաճառքի բնակարանների գների վիճակագրությունը
        </Typography>

        <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={0}>
            <AppWebsiteVisits
              title="Բնակարանի միջին գները մարզերում"
              subheader="Վերջին 10 ամիսները"
              chartColors={[
                theme.palette.success.dark,
                theme.palette.error.dark,
              ]}
              chartLabels={priceWeekly.label}
              chartData={[
                {
                  name: 'Բնակարանների Քանակը',
                  type: 'column',
                  fill: 'solid',
                  data: priceWeekly.count,
                },
                {
                  name: 'Միջին Գինը ($)',
                  type: 'line',
                  fill: 'area',
                  data: priceWeekly.avg,
                },
                {
                  name: 'Գնի կիսորդը(մեդիան) ($)',
                  type: 'line',
                  fill: 'solid',
                  data: priceWeekly.median,
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={0}>
              <AppWebsiteVisits
                  title="Մարզերի բնակարանների միջին գինը"
                  subheader="Վերջին 10 շաբաթները"
                  chartColors={[
                    theme.palette.success.dark,
                    theme.palette.error.dark,
                  ]}
                  chartLabels={priceWeeklyByProvince.label}
                  chartData={priceWeeklyByProvince.chartData}
                />
            </Grid>
          </Grid>
      </Container>
    </>
  );
}
