// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Ամփոփ Տվյալներ',
    path: '/dashboard/app',
    icon: <Iconify icon='material-symbols:dashboard' />,
  },
  {
    title: 'Երեւան',
    path: '/dashboard/yerevan',
    icon: <Iconify icon='mdi:house-city-outline' />,
  },
  {
    title: 'Մարզեր',
    path: '/dashboard/province',
    icon: <Iconify icon='mdi:house-city' />,
  },
  {
    title: 'Մեր Մասին',
    path: '/dashboard/about',
    icon: <Iconify icon='mdi:about' />,
  },
  // {
  //   title: 'login',
  //   // path: '/login',
  //   icon: icon('ic_lock'),
  // },
];

export default navConfig;
