import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import YerevanAppPage from './pages/YerevanAppPage';
import ProvinceAppPages from './pages/ProvinceAppPage';
import AboutAppPages from './pages/AboutAppPage';
import ChallengeFile from './pages/ChallengeFile';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
     {
       path: '/.well-known/acme-challenge/:challengeId',
       element: <ChallengeFile />,
     },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'yerevan', element: <YerevanAppPage /> },
        { path: 'province', element: <ProvinceAppPages /> },
        { path: 'about', element: <AboutAppPages /> },
      ],
    },
    // {
    //   path: 'login',
    //   element: <LoginPage />,
    // },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
