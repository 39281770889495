import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

function sendToGoogleAnalytics({name, delta, value, id}) {
    if (window && window.gtag) {
        // Assumes the global `gtag()` function exists, see:
        // https://developers.google.com/analytics/devguides/collection/ga4
        window.gtag('event', name, {
            // Built-in params:
            value: delta, // Use `delta` so the value can be summed.
            // Custom params:
            metric_id: id, // Needed to aggregate events.
            metric_value: value, // Optional.
            metric_delta: delta, // Optional.

            // OPTIONAL: any additional params or debug info here.
            // See: https://web.dev/debug-performance-in-the-field/
            // metric_rating: 'good' | 'needs-improvement' | 'poor',
            // debug_info: '...',
            // ...
        });
    }
}

reportWebVitals(sendToGoogleAnalytics);
