import { Helmet } from 'react-helmet-async';
import React from "react";
// @mui
import { Grid, Container, Typography } from '@mui/material';

// ----------------------------------------------------------------------
const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
});

export default function AboutAppPage() {

  return (
    <>
      <Helmet>
        <title> Մեր Մասին </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
            Մեր Մասին
        </Typography>
        <main>
          <div className={styles.heroContent}>
            <Typography variant="h6" align="center" color="textSecondary" component="p">
              Կայքը տրամադրում է վիճակագրական տվյալներ շուկայում վաճառվող բնակարանների մասին: Նպատակն է հեշտացնել ամենօրյա փնտրտուքը, 
              ցուցադրել վերջին շաբաթների ընթացքում շուկայում տիրող իրավիճակը, ինչպես նաեւ տվյալ տարածքում գործող գների մասին պատկերացում տալ: 
            </Typography>
          </div>
        </main>
        <Grid container spacing={3}>
        </Grid>
      </Container>
    </>
  );
}
